import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Client_Id } from './app/_core/constant';
import { ThemeProvider } from '@emotion/react';
import MUI_Theme from './assets/theme/mui-theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={Client_Id}>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={MUI_Theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
