// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-model-container {
  background-color: transparent;
}

.preview-close-container {
  position: absolute;
  top: 12px;
  right: 10px;
  color: white;
  cursor: pointer;
  z-index: 99999;
}

.preview-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.action-container {
  width: 50wh;
  height: 50wh;
}

.preview-icon_holder, .preview-right-arrow, .preview-left-arrow {
  height: 32px;
  width: 32px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  z-index: 99999;
}

.preview-left-arrow {
  left: 12px;
  transform: rotate(180deg);
}

.preview-right-arrow {
  right: 12px;
}

.preview_image_size {
  max-width: 100%;
  max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/_shared/components/app-image-preview/image-preview-popup.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,wCAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;AACJ;;AAEA;EAEI,UAAA;EACA,yBAAA;AAAJ;;AAGA;EAEI,WAAA;AADJ;;AAIA;EACI,eAAA;EACA,gBAAA;AADJ","sourcesContent":[".preview-model-container {\n    background-color: transparent;\n}\n\n.preview-close-container {\n    position: absolute;\n    top: 12px;\n    right: 10px;\n    color: white;\n    cursor: pointer;\n    z-index: 99999;\n}\n\n.preview-content {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.action-container {\n    width: 50wh;\n    height: 50wh;\n}\n\n.preview-icon_holder {\n    height: 32px;\n    width: 32px;\n    background-color: var(--secondary-color);\n    border-radius: 50%;\n    display: grid;\n    place-items: center;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    margin: auto 0;\n    cursor: pointer;\n    z-index: 99999;\n}\n\n.preview-left-arrow {\n    @extend .preview-icon_holder;\n    left: 12px;\n    transform: rotate(180deg);\n}\n\n.preview-right-arrow {\n    @extend .preview-icon_holder;\n    right: 12px;\n}\n\n.preview_image_size {\n    max-width: 100%;\n    max-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
