import { Subject } from 'rxjs';

const subject = new Subject<any>();

const getLoader = subject.asObservable();

const showLoader = (value: Boolean) => {
    subject.next(value);
}

const AppLoaderService = {
    getLoader,
    showLoader
}

export default AppLoaderService;


