import '../side-navbar/side-nav.scss';
import config from '../../../../config/menuJson.json';
import { JSXElementConstructor, Key, ReactElement, ReactNode, useEffect, useState } from 'react';
import AuthService from '../../../_service/auth.service';
import { useLocation, useNavigate } from 'react-router';
import { MAIN_PATH } from '../../../_core/constant';
import { SideNavBar } from '../../../enum/side-nav-enum';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';


const { banner, footer, settings, menu } = config;

interface SideNavProps {
    onChange: (e: boolean) => void;
}

const SideNav: React.FC<SideNavProps> = ({ onChange }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [source, setSource] = useState(banner.src);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
    const location = useLocation();

    useEffect(() => {
        const globalClick = (event: any) => {
            if (!event.target.closest('.sideNav')) {
                if (isOpen) { handleClick(isOpen); }
            }
        }
        document.addEventListener('click', globalClick);

        handleMenuSelectionRefresh();

        return () => {
            document.removeEventListener('click', globalClick)
        }
    }, [isOpen, location]);

    // Function to hadle hard refresh
    const handleMenuSelectionRefresh = () => {
        switch (location.pathname) {
            case MAIN_PATH.dashboard:
                setSelectedIndex(SideNavBar.Dashboard);
                break;
            case MAIN_PATH.taskAssignment:
                setSelectedIndex(SideNavBar.TaskAssignment);
                break;
            case MAIN_PATH.documentCenter:
                setSelectedIndex(SideNavBar.DocumentCenter);
                break;
            case MAIN_PATH.feedback:
                setSelectedIndex(SideNavBar.Feedback);
                break;
            case (MAIN_PATH.flaggedReport):
                setSelectedIndex(SideNavBar.FlaggedReports);
                break;
            case MAIN_PATH.shipmentSupport:
                setSelectedIndex(SideNavBar.ShipmentSupport);
                break;
            case MAIN_PATH.settings:
                setSelectedIndex(SideNavBar.Settings);
                break;
            default:
                setSelectedIndex(SideNavBar.Dashboard);
        }
    }

    const handleClick = (isOpen: boolean) => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setTimeout(() => {
                setSource(banner.logo);
                setIsRendered(true);
            }, 200);
        } else {
            setSource(banner.src);
            setIsRendered(false);
        }
        onChange(!isOpen);
    }

    const menuClick = (e: number) => {
        switch (e) {
            case 1:
                navigate(MAIN_PATH.dashboard);
                setSelectedIndex(SideNavBar.Dashboard);
                break;
            case 2:
                navigate(MAIN_PATH.taskAssignment);
                setSelectedIndex(SideNavBar.TaskAssignment);
                break;
            case 3:
                navigate(MAIN_PATH.documentCenter);
                setSelectedIndex(SideNavBar.DocumentCenter);
                break;
            case 4:
                navigate(MAIN_PATH.feedback);
                setSelectedIndex(SideNavBar.Feedback);
                break;
            case 5:
                navigate(MAIN_PATH.flaggedReport);
                setSelectedIndex(SideNavBar.FlaggedReports);
                break;
            case 6:
                navigate(MAIN_PATH.shipmentSupport);
                setSelectedIndex(SideNavBar.ShipmentSupport);
                break;
            case 7:
                navigate(MAIN_PATH.settings);
                setSelectedIndex(SideNavBar.Settings);
                break;
            default: break;
        }
    }

    const logOut = () => {
        AuthService.loggedOut();
        navigate('/login', { replace: true });
    }

    return (
        <div className='sideNav'>
            <div className='sub-container'>
                <div className={`${isOpen ? 'extend' : ''} sideNav-container`}
                >
                    <div className='app-banner'>
                        <img className='banner-image' src={source} alt={banner.name} />
                    </div>
                    {/* <div className='menu-container'>
                        {
                            menu.map((element: any, index: number) =>
                                <div className={`${isOpen && isRendered ? 'expanded' : ''} ${selectedIndex === index ? 'menu-selected' : 'menu-deselected'}`} key={index} onClick={() => menuClick(element.id)}>
                                    {element.id == 1 && <BarChartOutlinedIcon />}
                                    {element.id === 2 && <FactCheckOutlinedIcon />}
                                    {element.id === 3 && <ChatBubbleOutlineIcon />}
                                    {element.id == 4 && <FlagOutlinedIcon />}
                                    {element.id == 5 && <Inventory2OutlinedIcon/>}
                                    {
                                        isOpen && isRendered ? (
                                            <p className={`menu-text text-bold ${selectedIndex === index ? 'selected-text' : 'text'}`}>{element.name}</p>
                                        ) : ''
                                    }
                                </div>
                            )
                        }
                    </div>  */}
                    <div className='menu-container'>
                        {menu.map((element: { name: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | null | undefined; id: number; }, index: Key | null | undefined) => (
                            <Tooltip arrow placement="right" key={index} title={element.name}
                                classes={{ tooltip: `${isOpen ? "no_visible" : "tooltip"}` }}
                                slotProps={{
                                    popper: {
                                        modifiers: [{
                                            name: 'offset',
                                            options: {
                                                offset: [0, 14],
                                            }
                                        }]
                                    }
                                }}>
                                <div className={`${isOpen && isRendered ? 'expanded' : ''} ${selectedIndex === index ? 'menu-selected' : 'menu-deselected'}`} onClick={() => menuClick(element.id)}>
                                    {element.id === 1 && <BarChartOutlinedIcon />}
                                    {element.id === 2 && <AccountTreeOutlinedIcon />}
                                    {element.id === 3 && <FactCheckOutlinedIcon />}
                                    {element.id === 4 && <ChatBubbleOutlineIcon />}
                                    {element.id === 5 && <FlagOutlinedIcon />}
                                    {element.id === 6 && <Inventory2OutlinedIcon />}
                                    {isOpen && isRendered && (
                                        <p className={`menu-text text-bold ${selectedIndex === index ? 'selected-text' : 'text'}`}> {element.name}</p>
                                    )}
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                    <div className='outer-container' onClick={() => menuClick(settings.id)}>
                        <div className={`${isOpen ? 'expanded pl-5' : ''} settings ${selectedIndex === 7 ? 'menu-selected' : 'menu-deselected'}`}>
                            {/* <img width='20px' src={settings.src} alt={settings.name} /> */}
                            <SettingsIcon />
                            {
                                isOpen ? (
                                    <p className='menu-text text-bold'>{settings.name}</p>
                                ) : (
                                    ''
                                )
                            }
                        </div>
                    </div>
                    <div className={`${isOpen ? 'expanded pl-10' : ''} justify-center logout`} onClick={() => logOut()}>
                        {/* <img src={footer.src} alt={footer.name} /> */}
                        <LogoutIcon />
                        {
                            isOpen ? (
                                <p className='menu-text text-bold'>{footer.name}</p>
                            ) : (
                                ''
                            )
                        }
                    </div>
                </div>
                <button className={`${isOpen ? 'opened' : ''} handle`} onClick={() => handleClick(isOpen)}>
                    {/* <img src="https://s3.us-east-2.amazonaws.com/images.media.vsdev/uploads/arrow.png" alt="arrow" /> */}
                    <div id="img">
                        < ArrowForwardIosIcon fontSize="small" />
                    </div>

                </button>
            </div>
        </div>
    )
}

export default SideNav;