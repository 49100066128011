import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { AppLoader } from "../../../_models/app-loaderr-model";
import AppLoaderService from "../../../_service/app-loader-service";
import { logger } from "../../../_core/logger.service";

function AppLoaderView() {
    const [showLoading, setState] = useState(false);
    var loaderCount: number = 0;

    useEffect(() => {
        const subscription = AppLoaderService.getLoader.subscribe((value) => {
            if (value) { loaderCount++; } else { loaderCount--; }
            if (loaderCount < 0) {
                logger.warn(`loader counter is negative: ${loaderCount}`);
                loaderCount = 0;
            }
            setState(loaderCount > 0);

            return () => { subscription.unsubscribe(); };
        });
    }, []);

    return (
        <>
            {showLoading &&
                <div
                    className="showLoader"
                >
                    <CircularProgress size={100} sx={{ color: 'primary.main', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', width: '200px', height: '200px', zIndex: 9999 }} />
                </div>
            }
        </>
    );
};

export default AppLoaderView;