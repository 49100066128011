// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  --app-regular-font: "JosefinSansRegular";
  --app-medium-font: "JosefinSansMedium";
  --app-semibold-font: "JosefinSansSemiBold";
  --secondary-regular-font: "JosefinSlabRegular";
  --secondary-medium-font: "JosefinSlabMedium";
  --secondary-semibold-font: "JosefinSlabSemiBold";
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --tertiary-color: #1E1E1E;
  --quaternary-color: #EDF2FB;
  --quinary-color: #8E8E8E;
  --senary-color: #D7DCE4;
  --line-border-color: #8e8e8e26;
  --hover-color: #EBEBEB;
  --link-color: #0000EE;
  --success-color: #29BF12;
  --fail-color: #EF233C;
  --link-color: #17ADDC;
}

@media (prefers-color-scheme: dark) {
  body {
    --primary-color: #000000;
    --secondary-color: #ffffff;
    --tertiary-color: #1E1E1E;
    --quaternary-color: #EDF2FB;
    --quinary-color: #8E8E8E;
    --senary-color: #D7DCE4;
    --line-border-color: #8e8e8e26;
    --hover-color: #EBEBEB;
    --link-color: #0000EE;
    --success-color: #29BF12;
    --fail-color: #EF233C;
    --link-color: #17ADDC;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/theme/variables.scss"],"names":[],"mappings":"AAAA;EAEI,wCAAA;EACA,sCAAA;EACA,0CAAA;EACA,8CAAA;EACA,4CAAA;EACA,gDAAA;EAGA,wBAAA;EACA,0BAAA;EACA,yBAAA;EACA,2BAAA;EACA,wBAAA;EACA,uBAAA;EACA,8BAAA;EACA,sBAAA;EACA,qBAAA;EACA,wBAAA;EACA,qBAAA;EACA,qBAAA;AAFJ;;AAKA;EACI;IACI,wBAAA;IACA,0BAAA;IACA,yBAAA;IACA,2BAAA;IACA,wBAAA;IACA,uBAAA;IACA,8BAAA;IACA,sBAAA;IACA,qBAAA;IACA,wBAAA;IACA,qBAAA;IACA,qBAAA;EAFN;AACF","sourcesContent":["body {\n    // Fonts\n    --app-regular-font: 'JosefinSansRegular';\n    --app-medium-font: 'JosefinSansMedium';\n    --app-semibold-font: \"JosefinSansSemiBold\";\n    --secondary-regular-font: 'JosefinSlabRegular';\n    --secondary-medium-font: 'JosefinSlabMedium';\n    --secondary-semibold-font: \"JosefinSlabSemiBold\";\n\n    // Colors\n    --primary-color: #000000;\n    --secondary-color: #ffffff;\n    --tertiary-color: #1E1E1E;\n    --quaternary-color: #EDF2FB;\n    --quinary-color: #8E8E8E;\n    --senary-color: #D7DCE4;\n    --line-border-color: #8e8e8e26;\n    --hover-color: #EBEBEB;\n    --link-color: #0000EE;\n    --success-color: #29BF12;\n    --fail-color: #EF233C;\n    --link-color: #17ADDC;\n}\n\n@media (prefers-color-scheme: dark) {\n    body {\n        --primary-color: #000000;\n        --secondary-color: #ffffff;\n        --tertiary-color: #1E1E1E;\n        --quaternary-color: #EDF2FB;\n        --quinary-color: #8E8E8E;\n        --senary-color: #D7DCE4;\n        --line-border-color: #8e8e8e26;\n        --hover-color: #EBEBEB;\n        --link-color: #0000EE;\n        --success-color: #29BF12;\n        --fail-color: #EF233C;\n        --link-color: #17ADDC;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
