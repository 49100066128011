import { authKey } from '../_core/constant/storageKey';
import { decodeToken } from '../utils/jwt';
import StorageService from './storage.service';
import http from '../_core/helpers/axios/axios-instance';
import { JwtDetails } from '../_models/auth-model';
import { Auth } from '../_models/auth';

const GET_USER = '/login';
const GGLOGIN = 'support/auth/google';

const setUserDetails = (accessToken: any) => {
    return StorageService.setUserInfoToStorage(accessToken);
}

const getUserInfo = () => {
    const authToken = StorageService.getFromLocalStorage('token');  // For testing purpose this has been commented
    if (authToken) {
        const decodedToken: JwtDetails = decodeToken(authToken);
        return decodedToken
    } else {
        return null
    }
}

const isLoggedIn = () => {
    const authToken = StorageService.getFromLocalStorage(authKey);
    return !!authToken;
}

const loggedOut = () => {
    StorageService.removeFromLocalStorage('google_sso');
    StorageService.removeFromLocalStorage(authKey);
}

const googleSignIn = (clientToken: String) => {
    const payload = {
        'google_access_token': clientToken,
        'is_support_user': true
    }
    return http.post<Auth>(GGLOGIN, payload);
}

const getUser = (token: String) => {
    return token
    return http.get<any>(GET_USER, {
        params: {
            token
        }
    });
}

const AuthService = {
    setUserDetails,
    getUserInfo,
    isLoggedIn,
    loggedOut,
    getUser,
    googleSignIn
};

export default AuthService;