import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Theme, createStyles, makeStyles, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppModelService from "../../../_service/app-model-service";
import { PopUpModel } from "../../../_models/popUp-Model";
import CloseIcon from '@mui/icons-material/Close';
import './app-model-component.scss';
import { DataTypes } from "../../../enum/data-types";

export default function AppModelComponent() {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<PopUpModel>({
        header: true,
        footer: true,
        headerName: '',
        compoent: <></>,
        width: 500,
        height: 500,
        class: '',
        showCloseIcon: false,
        getFullSize: false
    });

    useEffect(() => {
        const AppModelServiceSubscription = AppModelService.getPopUp().subscribe((item: PopUpModel) => {
            if (item) {
                console.log("item", item);
                
                if (item.closePopUp) {
                    setOpen(false);
                    return;
                }
                setContent(
                    {
                        headerName: item.headerName,
                        compoent: item.compoent as React.ReactElement,
                        width: item.width,
                        height: item.height,
                        header: item.header ?? true,
                        footer: item.footer ?? true,
                        class: item.class ?? '',
                        showCloseIcon: item.showCloseIcon ?? false,
                        getFullSize: item.getFullSize ?? false
                    }
                );

                setOpen(true);
            }
        });

        return () => { AppModelServiceSubscription.unsubscribe(); };
    }, []);

    const handleClose = () => {
        setOpen(false);
        AppModelService.closePopUpCallback();
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="xl"
                sx={{
                    '& .MuiDialog-paper': {
                        width: typeof(content.width) === DataTypes.string ? `${content.width}` : `${content.width}px`,
                        height: typeof(content.height) === DataTypes.string ? `${content.height}` : `${content.height}px`,
                        overflow: 'hidden'
                    },
                }}
                classes={{ paper:`${ content.getFullSize ? "useFullSize" : "" } ${content.class ?? content.showCloseIcon ? 'close_icon_view' : ''}` }}
            >
                {content.header &&
                    <DialogTitle id="responsive-dialog-title" className='dialouge-title-container'>
                        {content.headerName}
                        {/* <Box onClick={handleClose}>
                            <CloseIcon className="popup-close-container" />
                        </Box> */}
                    </DialogTitle>
                }
                {content.compoent &&
                    <DialogContent className={`${content.class ? content.class : 'dialouge-content-container'}`} dividers>
                        {content.compoent}
                    </DialogContent>
                }
                {content.footer &&
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Disagree
                        </Button>
                        <Button onClick={handleClose} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                }
                {content.showCloseIcon &&
                    <div className="cancel_icon_holder" onClick={handleClose}>
                        <CloseIcon className="icon_close" />
                    </div>
                }
            </Dialog>
        </React.Fragment>
    );
}