import { useEffect, useState } from 'react';
import '../header/header.scss';
import AuthService from '../../../_service/auth.service';
import { useLocation } from 'react-router';
import { MAIN_PATH } from '../../../_core/constant';
import { useSelector } from 'react-redux';
import { RootReducer } from '../../../_models/root-reducer-model';
import { User } from '../../../_models/user';

const Header = () => {
    // const userDetails = AuthService.getUserInfo();
    const location = useLocation();
    const [headerName, setHeaderName] = useState<string>('');
    const userDetails: User = useSelector((state: RootReducer) => state.user.data);

    useEffect(() => {
        updateHeaderName();
    }, [location]);

    function updateHeaderName() {
        switch (location.pathname) {
            case MAIN_PATH.dashboard:
                setHeaderName('Dashboard');
                break;
            case MAIN_PATH.documentCenter:
                setHeaderName('Document Center');
                break;
            case MAIN_PATH.feedback:
                setHeaderName('Feedback');
                break;
            case (MAIN_PATH.flaggedReport):
                setHeaderName('Flagged Report');
                break;
            case MAIN_PATH.shipmentSupport:
                setHeaderName('Shipment Support');
                break;
            case MAIN_PATH.settings:
                setHeaderName('Settings');
                break;
            default:
                setHeaderName('Dashboard');
        }
    }

    return (
        <div className="header">
            <label className="page-header">{headerName}</label>
            <div className='align-center gap-10'>
                {
                    userDetails ? <>
                        <label className="regular-22 font-color">{userDetails && userDetails?.name}</label>
                        <img className="profile-image" src={userDetails.profilePic ? userDetails.profilePic : ""}></img>
                    </> : ""
                }

            </div>
        </div>
    )
}

export default Header;