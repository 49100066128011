// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideNav {
  grid-area: menu;
}

.header {
  grid-area: header;
}

.main-content {
  grid-area: main;
  height: 100% !important;
  overflow: hidden;
  padding-right: 20px;
}

.parent {
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-areas: "menu header" "menu main";
  grid-template-rows: 80px auto;
  height: calc(100vh - 40px);
  overflow: hidden;
  padding: 20px 0 20px 20px;
  background-color: var(--quaternary-color);
}

.parent > .header,
.parent > .main-content {
  grid-column-start: 1;
  margin-left: 100px;
}

.opened-navBar {
  grid-template-columns: 300px auto !important;
}

.loader-container {
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home-route/home-route-layout.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gCAAA;EACA,8CACoB;EAEpB,6BAAA;EACA,0BAAA;EACA,gBAAA;EACA,yBAAA;EACA,yCAAA;AADJ;;AAIA;;EAEI,oBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,4CAAA;AADJ;;AAIA;EACI,aAAA;AADJ","sourcesContent":[".sideNav {\n    grid-area: menu;\n}\n\n.header {\n    grid-area: header;\n}\n\n.main-content {\n    grid-area: main;\n    height: 100% !important;\n    overflow: hidden;\n    padding-right: 20px;\n}\n\n.parent {\n    display: grid;\n    grid-template-columns: 80px auto;\n    grid-template-areas: \n                        'menu header'\n                        'menu main';\n    grid-template-rows: 80px auto;\n    height: calc(100vh - 40px);\n    overflow: hidden;\n    padding: 20px 0 20px 20px;\n    background-color: var(--quaternary-color);\n}\n\n.parent > .header,\n.parent > .main-content {\n    grid-column-start: 1;\n    margin-left: 100px;\n}\n\n.opened-navBar {\n    grid-template-columns: 300px auto !important;\n}\n\n.loader-container {\n    z-index: 9999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
