import { createSlice } from "@reduxjs/toolkit";
import { IUserSlice } from "../../_models/user";

const initialState: IUserSlice = {
    data: null
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUserDetails: (state, action) => {
            state.data = action.payload;
        }
    }
})

export const {saveUserDetails} = userSlice.actions;

export default userSlice.reducer