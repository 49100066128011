// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackBar {
  left: 0 !important;
  right: 0 !important;
  justify-content: center !important;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/_shared/components/toaster/app-toaster.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,kCAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;AACJ","sourcesContent":[".snackBar {\n    left: 0 !important;\n    right: 0 !important;\n    justify-content: center !important;\n    width: fit-content;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
