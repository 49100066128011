import { createTheme } from '@mui/material';
import '../theme/variables.scss';

const MUI_Theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Use the Sass variable here
    },
    secondary: {
      main: "#8E8E8E"
    }
  },
});

export default MUI_Theme;