import axios, { AxiosError, AxiosResponse } from "axios";
import StorageService from "../../../_service/storage.service";
import { getEnvironment } from '../../constant/env-config';
import { authKey } from "../../constant/storageKey";
import getBaseUrl from "../../../../environment";
import AppLoaderService from "../../../_service/app-loader-service";
import AppToasterService from "../../../_service/app-alert-service";

interface MyErrorResponse {
    message: string;
    code: number;
}

const http = axios.create({
    baseURL: getBaseUrl(getEnvironment),
    headers: {
        "Content-type": "application/json",
    },
    timeout: 60000
});

http.interceptors.request.use((config) => {
    const accessToken = StorageService.getFromLocalStorage(authKey);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    window.alert(error.message);
    return Promise.reject(error);
});

// Add a response interceptor
http.interceptors.response.use(
    // Handle successful responses
    (response: AxiosResponse) => {
        return response.data;
    },
    // Handle errors
    (error: AxiosError) => {
        AppLoaderService.showLoader(false);
        const myErrorResponse = error.response?.data as MyErrorResponse;
        if (error.response?.status === 401) {
            AppToasterService.showToaster({
                severity: "error",
                message: `${myErrorResponse.message ?? error.response.statusText}`
            })
            StorageService.removeFromLocalStorage(authKey);
            return Promise.reject(myErrorResponse);
        }
        return Promise.reject(myErrorResponse);
    }
);

export default http;
