import { createSlice } from "@reduxjs/toolkit";
import { FlaggedReportSlice, InitialComments } from "../../_models/report";
// import { UserState } from "../../_models/user";

const initialState: FlaggedReportSlice = {
    data: {},
    commentsDetails: InitialComments
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        reportDetails: (state, action) => {
            state.data = action.payload
        },
        getReportDetail: (state) => {
            state
        },
        getCommentsForPost: (state, action) => {
            state.commentsDetails = action.payload;
        }
    }
})

export const { reportDetails, getReportDetail, getCommentsForPost } = reportSlice.actions;

export default reportSlice.reducer;
