import { IMenuItem, User } from "../_models/user";
import http from "../_core/helpers/axios/axios-instance";
import { ReportAttachments, ReportDetail } from "../_models/report";
import { ReportedList } from "../_models/app-common-model";
import { Params } from "../_models/app-common-model";

const GET_USER = '/user/me';
const GET_REPORTED_LIST = '/support/reports';
const GET_SUPPORT_USERS = '/support/users';
const GET_REPORT_DETAILS = '/support/reports/:reportId'
const USER_WITH_ID = `support/profile`;
const GET_POST_BY_ID = '/support/reports/:reportId';
const SAVE_REPORT = '/support/reports';
const GET_COMMENTS = '/comments';
const GET_USERS_WITH_ID = '/user/list';
const GET_MENU_WITH_ID = '/user/briefcase/:menuId';

const getUser = () => {
    return http.get<Array<User>>(GET_USER);
};

const getReportList = (Params: Params) => {
    // const params: Params = {
    //     status,
    //     type
    // }

    // if (searchTerm) params.searchTerm = searchTerm;
    return http.post<ReportedList[]>(GET_REPORTED_LIST, Params);
}

const getSupportUsers = () => {
    return http.get<User[]>(GET_SUPPORT_USERS, {
        params: { page: 0 }
    });
}

const fetchDetailsByReportId = (reportId: number) => {
    let url = GET_POST_BY_ID.replace(':reportId', reportId.toString());
    return http.get<ReportDetail>(url);
}

const saveReport = (reportId: number | null, attachment: ReportAttachments) => {
    const attachments: ReportAttachments = { action: attachment.action, type: attachment.type }
    if (attachment.comments) attachments.comments = attachment.comments
    return http.put<ReportDetail>(SAVE_REPORT, {
        reportId,
        attachments
    });
}

const getCommentsByPostId = (postId: number) => {
    return http.get(GET_COMMENTS, {
        params: { postId }
    });
}

const getUserById = (userId: number) => {
    return http.get<any, User>(USER_WITH_ID, { params: { userId } })
}

const getUserList = (payload: number[]) => {
    return http.post<User[]>(GET_USERS_WITH_ID, { 'ids': payload })
}

const getBriefcaseDetails = (menuId: number) => {
    const url = GET_MENU_WITH_ID.replace(":menuId", menuId.toString());
    return http.get<IMenuItem>(url);
}

const UserService = {
    getUser,
    getReportList,
    fetchDetailsByReportId,
    saveReport,
    getCommentsByPostId,
    getUserById,
    getUserList,
    getSupportUsers,
    getBriefcaseDetails
};

export default UserService;
