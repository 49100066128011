import { ReactNode } from "react";
import { IMenuItem, Query, TaggedLocation, User, initialiazeQuery } from "./user";

export interface SegmentModel {
    name: string
    status: number
    icon?: ReactNode
    isSelected: boolean
};

export interface Attachments {
    height?: number | null;
    width?: number | null;
    type?: string;
    url: string;
    thumbnailUrl?: string
}

export const initializeAttachments = {
    height: null,
    width: null,
    type: '',
    url: ''
}

export interface Post {
    id: number;
    name: string;
    attachments: Attachments[];
    user: User;
    taggedPeople: number[];
    previewSong: PreviewSong;
    taggedLocation: TaggedLocation;
    taggedMenu: number;
    likes?: number;
    commentsCount?: number;
    shares?: number;
    type: string;
}

// export const initializePost = {
//     id: 0,
//     name: '',
//     attachments: [initializeAttachments],
//     user: initialUser,
//     taggedPeople: [],
//     previewSong: {
//         name: '',
//         music: '',
//         mixType: '',
//         profilePic: '',
//         externalUrl: ''
//     },
//     taggedLocation: {
//         placeId: '',
//         latitude: 0,
//         longitude: 0,
//         mainText: '',
//         secondaryText: ''
//     }
// }

export interface ReportDetail {
    id: number | null;
    user: User;
    assignee: User;
    attachments: Attachments[] | null;
    post: Post;
    likes: number;
    order: number;
    shares: number;
    comments: number,
    detail: string;
    isAppealed: boolean,
    isCompleted: boolean;
    query: Query;
    actionList: Query[];
    reportedUser: User;
}

// export const initialiazeReport: ReportDetail = {
//     id: null,
//     user: initialUser,
//     assignee: initialUser,
//     attachments: [],
//     post: initializePost,
//     likes: 0,
//     order: 0,
//     shares: 0,
//     comments: 0,
//     detail: '',
//     isAppealed: false,
//     isCompleted: false,
//     query: initialiazeQuery,
//     actionList: [initialiazeQuery],
//     reportedUser: initialUser
// }

export interface ReportAttachments {
    action?: number | null;
    comments?: string;
    type: number;
}

export interface Comments {
    userId: number;
    display_name: string | null;
    name: string;
    profilePic: string;
    level: number;
    content: string;
    likes: string;
    children: Comments[]
}

export const InitialComments: Comments = {
    userId: 0,
    display_name: '',
    name: '',
    profilePic: '',
    level: 0,
    content: '',
    likes: '',
    children: []
}

export interface PreviewSong {
    name: string;
    music: string;
    mixType?: String;
    profilePic?: string;
    externalUrl?: string;
}

export interface FlaggedReportSlice {
    data: ReportDetail | any,
    commentsDetails: Comments
}
