import { Dialog, DialogContent, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import { useEffect } from "react";
import AppPreviewModelService from "../../../_service/app-preview-model-service";
import { PreviewPopUpModel } from "../../../_models/popUp-Model";
import CloseIcon from '@mui/icons-material/Close';
import "../../components/app-image-preview/image-preview-popup.scss";
import arrow from '../../../../assets/icons/image_arrow.svg'
import AppLoaderService from "../../../_service/app-loader-service";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppPreviewModelComponent() {
    const [open, setOpen] = useState(false);

    const [content, setContent] = useState<PreviewPopUpModel>({
        headerName: '',
        attachments: [],
        previewSong: null,
        index: 0
    });

    const [currentIndex, setCurrentIndex] = useState(0);

    const [audio, setAudio] = useState<HTMLAudioElement>();

    const [holdClick, setHoldClick] = useState(false);

    const handleAudioLoaded = () => {
        if (open && audio) {
            audio.play();
        }
    };

    useEffect(() => {

        audio?.addEventListener('canplaythrough', handleAudioLoaded);

        const AppModelServiceSubscription = AppPreviewModelService.getPreviewPopUp().subscribe((item: PreviewPopUpModel) => {
            if (item) {
                setContent(
                    {
                        headerName: item.headerName,
                        attachments: item.attachments,
                        previewSong: item.previewSong
                    }
                );
                if (item.index) setCurrentIndex(item.index)
                setOpen(true);
                if (item.previewSong) {
                    setAudio(new Audio(item.previewSong.music));
                }
            }
        });

        return () => {
            AppModelServiceSubscription.unsubscribe();
            audio?.removeEventListener('canplaythrough', handleAudioLoaded);
        }
    }, [audio, open]);

    const pauseAudio = () => {
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setAudio(undefined);
            audio?.removeEventListener('canplaythrough', handleAudioLoaded);
        }
    };

    const handleClose = () => {
        setCurrentIndex(0);
        setOpen(false);
        AppPreviewModelService.closePreviewPopUp();
        pauseAudio();
    };

    const goToPrevious = () => {
        if (holdClick) return;
        setHoldClick(true);
        AppLoaderService.showLoader(true);
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? content.attachments.length - 1 : prevIndex - 1
        );
    }

    const goToNext = () => {
        if (holdClick) return;
        setHoldClick(true);
        AppLoaderService.showLoader(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.attachments.length);
    }

    const onLoad = () => {
        AppLoaderService.showLoader(false);
        setHoldClick(false);
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                TransitionComponent={Transition}
                sx={{
                    '& .MuiDialog-paper': {
                        background: "transparent"
                    },
                }}
            >
                <div className="preview-close-container">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                {currentIndex != 0 && <div className='preview-left-arrow action-container' onClick={(ev) => goToPrevious()}>
                    <img className='arrow_icon' src={arrow} alt="arrow" />
                </div>
                }
                {content.attachments.length > 1 && currentIndex != content.attachments.length - 1 &&
                    <div className='preview-right-arrow' onClick={(e) => goToNext()}>
                        <img className='arrow_icon' src={arrow} alt="arrow" />
                    </div>
                }

                <DialogContent>
                    <div className="preview-content">
                        {
                            content.attachments[currentIndex] != null ?
                                content.attachments[currentIndex].type == "image" || 
                                content.attachments[currentIndex].type == "png"
                                    ?
                                    <img
                                        src={content.attachments[currentIndex].url}
                                        alt={"item.title"}
                                        loading="lazy"
                                        className="preview_image_size"
                                        onLoad={onLoad}
                                    />
                                    :
                                    <video onLoad={onLoad} className="preview_image_size" controls>
                                        <source src={content.attachments[currentIndex].url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                : null
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}