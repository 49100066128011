import { Subject } from 'rxjs';
import { PopUpModel } from '../_models/popUp-Model';
import { CallbackFunction } from '../_types/app-model-types';

const subject = new Subject<any>();
let appModelStoredCallback: CallbackFunction | null = null;

const getPopUp = () => {
    return subject.asObservable();
};

const createPopUp = (value: PopUpModel, callback?: CallbackFunction | null) => {
    subject.next(value);
    if (callback) {
        appModelStoredCallback = callback;
    }
}

const closePopUpCallback = () => {
    if (appModelStoredCallback) { appModelStoredCallback(); }
}

const dismiss = () => {
    subject.next({ closePopUp: true });
}

const AppModelService = {
    getPopUp,
    createPopUp,
    closePopUpCallback,
    dismiss
}

export default AppModelService;
