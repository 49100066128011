import { Subject } from "rxjs";
import { CallbackFunction } from "../_types/app-model-types";
import { PreviewPopUpModel } from "../_models/popUp-Model";

const previewPopupSubject = new Subject<any>();
let appModelStoredCallback: CallbackFunction | null = null;

const getPreviewPopUp = () => {
    return previewPopupSubject.asObservable();
};

const createPreviewPopUp = (value: PreviewPopUpModel, callback?: CallbackFunction) => {
    previewPopupSubject.next(value);
    if (callback) {
        appModelStoredCallback = callback;
    }
}

const closePreviewPopUp = () => {
    if (appModelStoredCallback) { appModelStoredCallback(); }
}

const AppPreviewModelService = {
    getPreviewPopUp,
    createPreviewPopUp,
    closePreviewPopUp
}

export default AppPreviewModelService;
