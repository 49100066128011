import { configureStore } from '@reduxjs/toolkit'
import RootReducer from './root-reducer'

const store = configureStore({
    reducer: RootReducer,
    devTools: process.env.NODE_ENV !== 'production'
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
