import { AlertColor } from '@mui/material';
import { Subject } from 'rxjs';

export interface ToasterType {
    message: string;
    severity: AlertColor | undefined
}
// 'success' | 'info' | 'warning' | 'error';

const subject = new Subject<any>();

const getToaster = subject.asObservable();

const showToaster = (options: ToasterType) => {
    subject.next(options);
}

const AppToasterService = {
    getToaster,
    showToaster
}

export default AppToasterService;


