// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close_icon_view {
  margin: 0;
  padding: 32px;
  background: transparent !important;
  position: relative;
  box-shadow: none !important;
}

.cancel_icon_holder {
  height: 40px;
  width: 40px;
  background: var(--primary-color);
  display: grid;
  place-items: center;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.icon_close {
  color: var(--secondary-color);
}

.dialouge-title-container {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-align: center;
  font-family: var(--app-regular-font) !important;
  border-radius: 10px 10px 0 0;
}

.popup-close-container {
  position: absolute;
  top: 50px;
  right: 35px;
  color: white;
  cursor: pointer;
  align-content: end;
}

.useFullSize {
  max-width: unset !important;
  max-height: unset !important;
  margin: 0 !important;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/_shared/components/app-model/app-model-component.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,aAAA;EACA,kCAAA;EACA,kBAAA;EACA,2BAAA;AAAR;;AAIA;EACI,YAAA;EACA,WAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AADJ;;AAIA;EACI,6BAAA;AADJ;;AAIA;EACI,sCAAA;EACA,6BAAA;EACA,kBAAA;EACA,+CAAA;EACA,4BAAA;AADJ;;AAIA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AADJ;;AAIA;EACI,2BAAA;EACA,4BAAA;EACA,oBAAA;EACA,UAAA;AADJ","sourcesContent":[".close_icon {\n    &_view {\n        margin: 0;\n        padding: 32px;\n        background: transparent !important;\n        position: relative;\n        box-shadow: none !important;\n    }\n}\n\n.cancel_icon_holder {\n    height: 40px;\n    width: 40px;\n    background: var(--primary-color);\n    display: grid;\n    place-items: center;\n    border-radius: 50%;\n    position: absolute;\n    top: 12px;\n    right: 15px;\n    cursor: pointer;\n}\n\n.icon_close {\n    color: var(--secondary-color);\n}\n\n.dialouge-title-container {\n    background-color: var(--primary-color);\n    color: var(--secondary-color);\n    text-align: center;\n    font-family: var(--app-regular-font) !important;\n    border-radius: 10px 10px 0 0;\n}\n\n.popup-close-container {\n    position: absolute;\n    top: 50px;\n    right: 35px;\n    color: white;\n    cursor: pointer;\n    align-content: end;\n}\n\n.useFullSize {\n    max-width: unset !important;\n    max-height: unset !important;\n    margin: 0 !important;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
