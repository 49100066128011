import { createSlice } from "@reduxjs/toolkit";
import { IOrderSlice } from "../../_models/order";

const initialState: IOrderSlice = {
    data: {},
    productDetails: []
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        saveOrder: (state, action) => {
            state.data = action.payload
        },
        saveProduct: (state, action) => {
            state.productDetails = action.payload
        }
    }
});

export const { saveOrder, saveProduct } = orderSlice.actions;

export default orderSlice.reducer;
