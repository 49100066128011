import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AuthService from '../_service/auth.service';
import HomeRouteLayout from '../pages/home-route/home-route-layout';

const AuthGuard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const localAuth = AuthService.getUserInfo();
        if (!localAuth) { navigate('/login', { replace: true }) }
    }, [navigate])

    return <HomeRouteLayout />
}

export default AuthGuard;
