// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  height: 330px; /* Adjust as needed */
  background-color: var(--primary-color);
  width: 540px;
  position: relative;
  border-radius: 30px;
}

.login_main_container {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.login-loader-container {
  display: "grid";
  place-items: "center";
  height: "100vh";
}

.login_img_container {
  position: absolute;
  top: 55px;
  width: 270px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/login-page.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA,EAAA,wBAAA;EACA,uBAAA,EAAA,sBAAA;EACA,aAAA,EAAA,qBAAA;EACA,sCAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,qBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;AACF","sourcesContent":[".login-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* Center horizontally */\n  justify-content: center; /* Center vertically */\n  height: 330px; /* Adjust as needed */\n  background-color: var(--primary-color);\n  width: 540px;\n  position: relative;\n  border-radius: 30px;\n}\n\n.login_main_container {\n  width: 100%;\n  height: 100vh;\n  display: grid;\n  place-items: center;\n}\n\n.login-loader-container {\n  display: 'grid';\n  place-items: 'center';\n  height: '100vh';\n}\n\n.login_img_container {\n  position: absolute;\n  top: 55px;\n  width: 270px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
