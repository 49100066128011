import { useNavigate } from 'react-router-dom';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import StorageService from '../../_service/storage.service';
import { authKey } from '../../_core/constant/storageKey';
import '../login/login-page.scss';
import { MAIN_PATH } from '../../_core/constant';
import { useState } from 'react';
import { LinearProgress } from '@mui/material';
import AuthService from '../../_service/auth.service';
import { logger } from '../../_core/logger.service';
import AppToasterService from '../../_service/app-alert-service';
import Toaster from '../../_shared/components/toaster/app-toaster';
import { AxiosError } from 'axios';

const Login = (): any => {
    const navigate = useNavigate();
    const [showLoader, setLoader] = useState(false);

    const responseMessage = (response: CredentialResponse) => {
        if (response.credential) {
            fetchUserDetails(response.credential);
        }
    };

    const errorMessage = () => {
        window.alert("Login Error");
    };

    const fetchUserDetails = async (clientToken: String) => {
        // const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTg1LCJuYW1lIjoiUHJhYmh1IiwiYWNjb3VudF90eXBlIjoiYnVzaW5lc3MiLCJpYXQiOjE3MDI4OTIzNjQsImV4cCI6MTczNDQyODM2NH0.cn4bxV9PepDRTRy2kyumc_9ZP_v-rB--wCw12yy3NJk";

        setLoader(true);
        AuthService.googleSignIn(clientToken).then((res: any) => {
            console.log(res);
            StorageService.setLocalStorage(authKey, res.token);
            // StorageService.setLocalStorage("google_sso", clientToken);
            navigate(MAIN_PATH.flaggedReport);
            setLoader(false);

        }).catch((error: AxiosError) => {
            logger.error(error.message);
            setLoader(false);
            AppToasterService.showToaster({
                severity: "error",
                message: error.response?.statusText ?? error.message
            });
            navigate('/login', { replace: true });
        });

        // await AuthService.getUser(clientToken).then((res) => {
        // }).catch((err: any) => {
        //     logger.debug(err);
        // });
    }
    return (
        <>
            {showLoader &&
                <div className='login-loader-container'>
                    <LinearProgress sx={{ color: 'primary.main', height: 5 }} />
                </div>

            }
            <div className='login_main_container'>
                <div className='login-container'>
                    <img src='https://s3.us-east-2.amazonaws.com/images.media.vsdev/uploads/Veersky.png' className='login_img_container' />
                    <GoogleLogin
                        onSuccess={responseMessage}
                        onError={errorMessage}
                        width={300}
                        theme='filled_black'
                        size='large'
                        shape='pill'
                    />
                </div>
            </div>
            <Toaster />
        </>

    );
}

export default Login;
