import { jwtDecode } from 'jwt-decode'

export const decodeToken = (token: string) => {
  return jwtDecode(token);
}

export const isTokenExpired = (token: string) => {
  if (!token) {
    return true; // Token is not provided
  }

  try {
    const decodedToken = decodeToken(token);
    // const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    const currentTime = Date.now();

    // return expirationTime < currentTime;
  } catch (error) {
    return true; // Token is invalid or expired
  }
}
