import exp from 'constants';
import { authKey } from '../_core/constant/storageKey';

const setUserInfoToStorage = (accessToken: any) => {
    return setLocalStorage(authKey, accessToken)
}

const setLocalStorage = (key: any, token: any) => {
    if (!key || typeof window === 'undefined') {
        return ''
    }
    return localStorage.setItem(key, token)
}

const getFromLocalStorage = (key: any) => {
    if (!key || typeof window === 'undefined') {
        return ''
    }
    return localStorage.getItem(key)
}

const removeFromLocalStorage = (key: any) => {
    if (!key || typeof window === 'undefined') {
        return ''
    }
    return localStorage.removeItem(key)
}

const StorageService = {
    setUserInfoToStorage,
    setLocalStorage,
    getFromLocalStorage,
    removeFromLocalStorage
};

export default StorageService;