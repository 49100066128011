import { IPackageDetails, IProduct } from "../../_models/app-product-model";

export const MAIN_PATH = {
    root: "/support",
    dashboard: "/support/dashboard",
    flaggedReport: "/support/flagged-report",
    feedback: "/support/feedback",
    documentCenter: "/support/document-center",
    shipmentSupport: "/support/shipment-support",
    settings: "/support/settings",
    taskAssignment: '/support/task-assignment'

};

export const Client_Id = "832283688358-2u1hnnv1p84cfjsk8ammd62eqggcqih7.apps.googleusercontent.com";

export const Client_Secret = "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";

export const Product_Keys_To_Extract: (keyof IProduct)[] = ['itemName', 'itemCondition', 'itemCost', 'seller', 'paymentMethod', 'carrier', 'shipmentMethod'];

export const Package_Keys_To_Extract: (keyof IPackageDetails)[] = ['pickUp', 'delivery', 'eta', 'status', 'trackId']
