import { Navigate, Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { MAIN_PATH } from "../_core/constant";
import { Suspense } from 'react';
import AuthGuard from "../_guard/authentication.guard";
import DashboardModule from "../pages/dashboard/dashboard.module";
import Login from "../pages/login/login-page";
import FlaggedReportModule from "../pages/flagged-report/flagged-report-module";
import DocumentCenterModule from "../pages/document-center/document-center-module";
import ChatSupportModule from "../pages/chat-support/chat-support-module";
import SettingsModule from "../pages/settings/settings-page-module";
import ShipmentSupportModule from "../pages/shipment-support/shipment-support.module";
import TaskAssignmentModule from "../pages/task-assignment/task-assignment.module";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


const AppRoutes = () => {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path={MAIN_PATH.root} element={<AuthGuard />}>
                        <Route path={MAIN_PATH.flaggedReport} element={<FlaggedReportModule />} />
                        <Route path={MAIN_PATH.dashboard} element={<DashboardModule />} />
                        <Route path={MAIN_PATH.documentCenter} element={<DocumentCenterModule />} />
                        <Route path={MAIN_PATH.feedback} element={<ChatSupportModule />} />
                        <Route path={MAIN_PATH.shipmentSupport} element={<ShipmentSupportModule />} />
                        <Route path={MAIN_PATH.settings} element={<SettingsModule />} />
                        <Route path={MAIN_PATH.taskAssignment} element={<TaskAssignmentModule />} />
                    </Route>
                    <Route path='/login' element={<Login />} />
                    <Route path='*' element={<Navigate to='/login' replace />} />
                </Routes>
            </Suspense>
        </Router>
    )
};

export default AppRoutes;
