// logger.ts

// Define log levels
enum LogLevel {
    Debug,
    Info,
    Warning,
    Error,
}

// Logger configuration
interface LoggerConfig {
    logLevel: LogLevel;
}

// Default logger configuration
const defaultConfig: LoggerConfig = {
    logLevel: LogLevel.Debug,
};

// Logger class
class Logger {
    private config: LoggerConfig;

    constructor(config: LoggerConfig = defaultConfig) {
        this.config = config;
    }

    // Log methods
    debug(message: string): void {
        if (this.config.logLevel <= LogLevel.Debug) {
            this.log('DEBUG', message);
        }
    }

    info(message: string): void {
        if (this.config.logLevel <= LogLevel.Info) {
            this.log('INFO', message);
        }
    }

    warn(message: string): void {
        if (this.config.logLevel <= LogLevel.Warning) {
            this.log('WARNING', message);
        }
    }

    error(message: string): void {
        if (this.config.logLevel <= LogLevel.Error) {
            this.log('ERROR', message);
        }
    }

    private log(level: string, message: string): void {
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] [${level}] ${message}`);
    }
}

// Create a singleton instance of the logger
const logger = new Logger();

export { Logger, logger, LogLevel };
