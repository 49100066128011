import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import './app-toaster.scss'
import { useEffect, useState } from 'react';
import AppToasterService, { ToasterType } from '../../../_service/app-alert-service';
import Alert from '@mui/material/Alert';

export default function Toaster() {
    const [state, setState] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<
            TransitionProps & {
                children: React.ReactElement<any, any>;
            }
        >;
    }>({
        open: false,
        Transition: Fade,
    });
    const [options, setOptions] = useState<ToasterType>({
        message: '',
        severity: undefined
    })

    useEffect(() => {
        const toasterSubscription = AppToasterService.getToaster.subscribe((item: ToasterType) => {
            setOptions({
                message: item.message,
                severity: item.severity
            });
            handleClick(SlideTransition)();
        });

        return () => { toasterSubscription.unsubscribe(); };
    }, []);


    function SlideTransition(props: SlideProps) {
        return <Slide {...props} direction="up" />;
    }

    const handleClick =
        (
            Transition: React.ComponentType<
                TransitionProps & {
                    children: React.ReactElement<any, any>;
                }
            >,
        ) => () => {
            setState({
                open: true,
                Transition,
            });
        };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    return (
        <div>
            <Snackbar
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                message='ALERT'
                key={state.Transition.name}
                className='snackBar'
                autoHideDuration={3000}
                >
                <Alert
                    severity={options.severity}
                    variant="filled"
                    className='snackBar'
                    sx={{ width: '100%' }}
                >
                    {options.message}
                </Alert>
            </Snackbar>
        </div>
    );
}