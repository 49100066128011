import { ThemeProvider } from '@mui/material';
import './App.scss';
import AppRoutes from './app/route';

function App(): any {
  return (
    <AppRoutes />
  );
}

export default App;
