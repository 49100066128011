import { combineReducers } from "@reduxjs/toolkit";
import reportSlice from "./feature/flagged-report-slice";
import orderSlice from "./feature/order-slice";
import userSlice from "./feature/user-slice";

const RootReducer = combineReducers({
    flaggedReport: reportSlice,
    order: orderSlice,
    user: userSlice
});

export type RootState = ReturnType<typeof RootReducer>;

export default RootReducer;
