import devConfig from "./dev-config";
import prodConfig from "./prod-config";
import stageConfig from "./stage-config";


const getBaseUrl = (env: string | undefined) => {
    // const env = process.env.REACT_APP_ENV;
    switch (env) {
        case 'prod':
            return prodConfig.apiUrl;
        case 'stage':
            return stageConfig.apiUrl;
        default:
            return devConfig.apiUrl;
    }
};

export default getBaseUrl;
