import { useEffect, useState } from 'react';
import Header from '../../_shared/components/header/header';
import SideNav from '../../_shared/components/side-navbar/side-nave';
import { Outlet, useNavigate } from 'react-router-dom';
import '../../pages/home-route/home-route-layout.scss';
import AuthService from '../../_service/auth.service';
import AppLoaderView from '../../_shared/components/app-loader/app-loader-view';
import AppModelComponent from '../../_shared/components/app-model/app-model-component';
import Toaster from '../../_shared/components/toaster/app-toaster';
import AppPreviewModelComponent from '../../_shared/components/app-image-preview/image-preview-popup';
import UserService from '../../_service/user.service';
import { error } from 'console';
import AppLoaderService from '../../_service/app-loader-service';
import AppToasterService from '../../_service/app-alert-service';
import { useDispatch } from 'react-redux';
import { saveUserDetails } from '../../redux/feature/user-slice';

const HomeRouteLayout = () => {
    const [navBarState, setNavBarState] = useState(false);
    const navBarChange = ((e: boolean) => setNavBarState(e));
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        const localAuth = AuthService.getUserInfo();
        if (!localAuth) { navigate('/login', { replace: true }) }
    }, [navigate]);

    useEffect(() => {
        AppLoaderService.showLoader(true);
        UserService.getUser()
        .then((res) => {
            dispatch(saveUserDetails(res));
            AppLoaderService.showLoader(false);
        }).catch((error) => {
            AppLoaderService.showLoader(false);
            AppToasterService.showToaster({
                severity: "error",
                message: error
            })
        })
    }, [])

    return (
        <>
            <div className={`${navBarState ? 'opened-navBar' : ''} parent`}>
                <SideNav onChange={navBarChange} />
                <Header />
                <div className='main-content'>
                    <Outlet />
                    <div className='loader-container'>
                        <AppLoaderView />
                    </div>
                    <Toaster />
                    <AppModelComponent />
                    <AppPreviewModelComponent />
                </div>
            </div>
        </>
    )
}

export default HomeRouteLayout;
